"use client";

import { useEffect } from "react";
import { useRouter } from "next/navigation";

export default function GlobalError({
  error,
  reset,
}: {
  error: Error;
  reset: () => void;
}) {
  const router = useRouter();

  useEffect(() => {
    console.error("💥 Global error caught:", error);

    // Redirect to homepage after 2 seconds
    const timer = setTimeout(() => {
      router.push("/");
    }, 2000);

    return () => clearTimeout(timer);
  }, [error, router]);

  return (
    <html>
      <body>
        <div style={{ textAlign: "center", marginTop: "50px" }}>
          <h2>Something went wrong. Redirecting you...</h2>
        </div>
      </body>
    </html>
  );
}
